export type PostTypes = "WpCta" |
	"WpDocumentation" |
	"WpFeaturePage" |
	"WpHomepage" |
	"WpLinkGroup" |
	"WpMediaItem" |
	"WpPage" |
	"WpPost" |
	"WpSiteMetadata";

export type MappedPostType<T extends PostTypes> = T extends "WpCta" ? Queries.WpCta :
	T extends "WpDocumentation" ? Queries.WpDocumentation :
	T extends "WpFeaturePage" ? Queries.WpFeaturePage :
	T extends "WpHomepage" ? Queries.WpHomepage :
	T extends "WpLinkGroup" ? Queries.WpLinkGroup :
	T extends "WpMediaItem" ? Queries.WpMediaItem :
	T extends "WpPage" ? Queries.WpPage :
	T extends "WpPost" ? Queries.WpPost :
	T extends "WpSiteMetadata" ? Queries.WpSiteMetadata :
	never;

export const isType = <T extends PostTypes>(
	link: Queries.WpPostObjectUnion & { __typename?: string }, tp: T): link is MappedPostType<T> => {
	return link.__typename === tp;
}
