import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

export const Logo = (props: any) => {
	const file = useStaticQuery<{ file: Queries.File }>(graphql`{
		file(relativePath: {eq: "PNG Logo Files/Transparent Logo.png"}) {
    	id
    	relativePath
    	childImageSharp {
				gatsbyImageData(width: 100)
    	}
  	}
	}`);
	console.log("file:", file);
	const image = getImage(file.file.childImageSharp?.gatsbyImageData!)!;
	return (
		<GatsbyImage image={image} alt="Misty Logo" />
	);
}