import React from "react";
import { Link } from "./LinkGropRender";

interface HeroProps {
	hero: Queries.WpHomepage_Hero;
}

export const CTA = (props: HeroProps) => {
	return (
		<div className="container">
			<div className="px-4 pt-5 my-5 text-center border-bottom">
				<div>
					<h1 className="display-4 fw-bold">{props.hero.title}</h1>
					<div className="col-lg-6 mx-auto">
						<p className="lead mb-4" dangerouslySetInnerHTML={{ __html: props.hero.byline! }}></p>
						<div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
							<Link
								link={props.hero.callToAction!}
								className="btn btn-outline-primary"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}