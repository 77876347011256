import { Link } from "gatsby";
// import "bootstrap/dist/js/bootstrap.min";
import React from "react";
import { LinkGroup } from "./LinkGropRender";
import { Logo } from "./logo";

interface NavProps {
	navGroup: Queries.WpLinkGroup;
}

export const Nav = (props: NavProps) => {
	console.log("pages:", props)
	return <nav className="navbar navbar-expand-lg navbar-light bg-light py-3">
		<div className="container">
			<div className="row justify-content-between col">
				<div className="col">
					{/* <Link className="navbar-brand" to="/">Misty</Link> */}
					<Link className="navbar-brand" to="/"><Logo /></Link>
				</div>
				<div className="col align-content-center d-flex">
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav me-auto mb-2 mb-lg-0">
							{props.navGroup.nav_links2!.group!.map(group => {
								if (group!.links == null) {
									return {
										...group,
										links: [],
									} as Queries.Maybe<Queries.WpLinkGroup_NavLinks2_group>
								}
								return group
							}).map(group => {
								console.log("GROUP:", group)
								return (
									<LinkGroup linkGroup={group!} />
								)
							})}
						</ul>
					</div>
				</div>
				<div className="col align-content-center d-flex justify-content-end">
					<div className="collapse navbar-collapse">
						<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
							<li className="nav-item">
								<Link className="btn btn-primary" to="/" role="button">Get a Demo</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</nav>
}