import React, { useMemo } from "react";
import { graphql, Link as GatsbyLink } from "gatsby";
import { isType } from "./LinkUtils";




const getURL = (link: Queries.WpPostObjectUnion & { __typename: string }) => {
	if (isType(link, "WpPost")) {
		return `/blog/${link.categories?.nodes![0]?.slug}/${link.desiredSlug ?? link.slug}`
	}
	if (isType(link, "WpFeaturePage") || isType(link, "WpHomepage")) {
		return `${link.page_meta?.path}`;
	}
	if (isType(link, "WpDocumentation")) {
		return `/docs/${link.desiredSlug ?? link.slug}`;
	}

	throw new Error(`Can not cast ${link.__typename} to a link: ${JSON.stringify(link)}`);
}

export const Link = (props: { link: Queries.WpLinkGroup_NavLinks2_group_links, className?: string }) => {
	if (props.link.linkType === "internal") {
		try {
			const url = getURL(props.link.internalLink! as any);
			// const internalLinkAsHP = props.link.internalLink as Queries.WpHomepage;
			// const url = internalLinkAsHP.page_meta?.path ?? props.link.internalLink?.desiredSlug;
			return (<GatsbyLink className={props.className} to={url!}>{props.link.text}</GatsbyLink>);
		} catch (e) {
			console.error(e, props.link);
			return null;
		}
	}
	if (props.link.linkType === "external") {
		return (<a href={props.link.externalLink!} className={props.className}>{props.link.text}</a>)
	}
	if (props.link.linkType === "email") {
		return <a href={`mailto:${props.link.email}`} className={props.className}>{props.link.text}</a>
	}
	throw new Error(`Unknown link type: ${props.link.linkType}`);
}

interface LinkProps {
	linkGroup: Queries.WpLinkGroup_NavLinks2_group;
}

export const LinkGroup = (props: LinkProps) => {
	const id = useMemo(() => Math.floor(Math.random() * 10000), []);
	const group = useMemo(() => props.linkGroup, []);
	return (
		<li className="nav-item dropdown">
			<a className="nav-link dropdown-toggle" href="#" id={`navbarDropdown${id}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
				{group.title ?? ""}
			</a>
			<ul className="dropdown-menu" aria-labelledby={`navbarDropdown${id}`}>
				{group.links!.map((link) => {
					return <li><Link link={link!} className="dropdown-item" /></li>
				})}
			</ul>
		</li>
	);
}

export const markdownNavLinkFragment = graphql`
fragment LinkedPost on WpPostObjectUnion {
  __typename
  ... on WpDocumentation {
    slug
    desiredSlug
  }
  ... on WpPost {
    id
    slug
    categories {
      nodes {
        slug
      }
    }
  }
  ... on WpHomepage {
    id
    page_meta {
      path
    }
  }
  ... on WpFeaturePage {
    id
    page_meta {
      path
    }
  }
}

fragment LinkGroup on WpLinkGroup {
  nav_links2 {
    ... on WpLinkGroup_NavLinks2 {
      group {
        navType
        title
        link {
          linkTo {
            ...LinkedPost
          }
        }
        links {
          email
          externalLink
          internalLink {
            ...LinkedPost
          }
          linkType
          text
        }
        title
      }
    }
  }
}

fragment NavLinksGroup on WpLinkGroup_NavLinks2_group {
  links {
    email
    externalLink
    internalLink {
			__typename
      ... on WpPost {
        id
        slug
        categories {
					nodes {
						slug
          }
        }
      }
			... on WpDocumentation {
        id
        page_meta {
          path
        }
				
			}
      ... on WpHomepage {
        id
        page_meta {
          path
        }
      }
      ... on WpFeaturePage {
        id
        page_meta {
          path
        }
      }
    }
    linkType
    text
  }
  title
}`
