import { graphql, PageProps } from "gatsby";
import React from "react";
import { CTA } from "../components/CTA";
import { Footer } from "../components/Footer";
import { Hero } from "../components/Hero";
import { Nav } from "../components/Nav";
import { Repeatable } from "../components/Repeatable";
import { SEO } from "../components/SEO";

const HomePageTemplate = (props: PageProps<{ data: Queries.WpHomepage }>) => {
	return (
		<>
			<Nav navGroup={props.data.data.page_meta!.navLink!} />
			<Hero hero={{ ...props.data.data.hero!, image: props.data.data.featuredImage!.node! }} />
			{props.data.data.repeatable_content?.repeatableContent!.map((content) => {
				return (
					<Repeatable content={content!} />
				)
			})}
			<CTA hero={props.data.data.hero!} />
			<Footer navGroup={props.data.data.page_meta?.footer?.nav_links2!} />
		</>
	)
}

export const Head = (props: PageProps<{ data: Queries.WpHomepage }>) => {
	return (
		<SEO title={props.data.data.title!} description={props.data.data.excerpt!.replace(/(<([^>]+)>)/gi, "")} pathname={props.data.data.page_meta?.path!} />
	)
}

export default HomePageTemplate;
export const query = graphql`
	query ($id: Int!) {
		data: wpHomepage (databaseId:{eq:$id}){
      databaseId
			title
			excerpt

			repeatable_content {
      	repeatableContent {
      	  title
      	  byline
					contentType
      	  cells{
      	  	image {
      	  		gatsbyImage(width:400)
      	  	}
      	    title
      	  	content
      	  }
    	  }
    	}

			featuredImage {
				node{
					sourceUrl
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1000)
							# fluid(maxWidth: 450) {
							# 	...GatsbyImageSharpFluid
							# }
						}
					}
				}
			}

			hero {
				title
				byline
				callToAction {
					text
					__typename
					linkType
					internalLink {
						... on WpPost {
							id
							slug
						}
						... on WpHomepage {
							id
							page_meta {
								path
							}
						}
					}
				}
			}
      page_meta {
        path
				footer {
					...LinkGroup
				}
				navLink {
					...LinkGroup
				}
      }
    }
	}
`;