import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

export const SideBySide = (props: { grid: readonly Queries.WpHomepage_RepeatableContent_repeatableContent_cells[] }) => {
	return (
		<>
			{props.grid.map((cell, ndx) => {
				const image = getImage(cell.image! as any)!;
				const className = ndx % 2 === 0 ? "flex-row" : "flex-row-reverse";
				return (
					<div className={`row ${className} my-5`}>
						<div className="col">
							<h4>{cell.title}</h4>
							<p dangerouslySetInnerHTML={{ __html: cell.content! }}></p>
						</div>
						<div className="col">
							<GatsbyImage image={image} alt={cell.image?.altText!} />
						</div>
					</div>
				)
			})}
		</>
	)
}

export const Grid = (props: { grid: readonly Queries.WpHomepage_RepeatableContent_repeatableContent_cells[] }) => {
	return (
		<div className="row">
			{props.grid.map(cell => {
				const image = getImage(cell.image! as any)!;
				return (
					<div className="col-md-4 col-sm-6 col-xs-12">
						<GatsbyImage image={image} alt={cell.image?.altText!} />
						<h4>{cell.title}</h4>
						<p dangerouslySetInnerHTML={{ __html: cell.content! }}></p>
					</div>
				)
			})}
		</div>
	)
}

export const Repeatable = (props: { content: Queries.WpHomepage_RepeatableContent_repeatableContent }) => {
	return (
		<div className="container my-5 text-center">
			<div>
				<h3 className="mb-4">{props.content.title}</h3>
				<div className="lead"></div>
				{props.content.contentType === "grid" && (<Grid grid={props.content.cells! as any} />)}
				{props.content.contentType === "sideBySide" && (<SideBySide grid={props.content.cells! as any} />)}
			</div>
		</div>
	);
}