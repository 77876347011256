import React from "react";
import { Link } from "./LinkGropRender";
import { Link as GatsbyLink } from "gatsby";

interface FooterProps {
	navGroup: Queries.WpLinkGroup_NavLinks2;
}

export const Footer = (props: FooterProps) => {
	return (
		<footer className="text-center text-lg-start bg-light text-muted border-top py-5">
			<div className="container">
				<section className="row ">
					{props.navGroup.group?.map(group => {
						return (<div className="col border-right">
							<p className="h4">{group?.title}</p>
							{group?.links?.map(link => <div className="py-2"><Link link={link!} /></div>)}
						</div>);
					})}
				</section>
				<section className="border-top mt-5 pt-3">
					<div className="row">
						<div className="col-md-2">
							<p>&copy; {new Date().getFullYear()} <a href="https://shuttl.io" target={"_blank"}>Shuttl, LLC </a></p>
						</div>
						<div className="col-md-2">
							<a href="/sitemap/sitemap-index.xml">Site Map</a>
						</div>
						<div className="col-md-2">
							<GatsbyLink to="/privacy-policy">Privacy Policy</GatsbyLink>
						</div>
						<div className="col-md-2">
							<GatsbyLink to="/terms-of-service">Terms of Service</GatsbyLink>
						</div>
					</div>
				</section>
			</div>
		</footer>
	);
}